<template>
    <div class="container">
        <h1 class="text-center">
            {{$t('typeSelection.heading')}}
        </h1>

        <div class="row mt-4 justify-content-around">
            <div class="col-12 col-md-5 my-4 text-center">
                <aspena-btn
                    size="lg"
                    variant="aspena-orange"
                    class="mb-4"
                    @click.prevent="onSetType('person')"
                >
                    <span class="iconify" data-icon="mdi:person"></span> {{$t('typeSelection.person.title')}}
                </aspena-btn>

                <p class="text-bigger font-weight-bold text-primary">
                    {{$t('typeSelection.person.content')}}
                </p>
            </div>

            <div class="col-12 col-md-5 my-4 text-center">
                <aspena-btn
                    size="lg"
                    variant="aspena-orange"
                    class="mb-4"
                    @click.prevent="onSetType('company')"
                >
                    <span class="iconify" data-icon="mdi:bank"></span> {{$t('typeSelection.company.title')}}
                </aspena-btn>

                <p class="text-bigger font-weight-bold text-primary">
                    {{$t('typeSelection.company.content')}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { BasicData } from '../../DTO'

export default {
    name: 'TypeSelectionPage',
    mounted () {
        this.$nextTick(() => {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 200)
        })
    },
    methods: {
        onSetType (type) {
            this.$store.commit('mergeData', new BasicData({ ...(this.$store.state.userData.basicData || {}), type }))
            this.$router.push({ name: 'basicData', params: { type: type } })
        }
    }
}
</script>
